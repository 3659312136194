@import-normalize;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body,
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

* {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444444;
  line-height: 1.5em;
}

.no-scroll {
  overflow: hidden;
}

.medium-editor-element {
  width: 100%;
}

html::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
html::-webkit-scrollbar-thumb {
  background: #97e0733d;
  border-radius: 5px;
}
